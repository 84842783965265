<template>
    <IonPage>
        <Header :title="getJurorName"></Header>
        <IonContent fullscreen>
            <IonGrid>
                <IonRow class="ion-justify-content-center ion-align-items-center">
                    <IonCol class="ion-margin-top ion-padding-top ion-text-center">
                        <ion-card color="light">
                            <ion-card-header>
                                <ion-card-title>Tischauswahl</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                            </ion-card-content>
                        </ion-card>
                    </IonCol>
                </IonRow>
                <IonRow class="ion-justify-content-center">
                    <template v-if="getTables.length === 0">
                        <IonCol  class="ion-text-center"  size-xs="12" size-md="12" size-xl="12">
                            <ion-card color="light">
                                <div>
                                    <div class="card-pre-header danger"></div>
                                </div>
                                <ion-card-content>
                                    <br>
                                    <p class="ion-margin">Es sind Ihnen zurzeit keine Tische zugewiesen. Bitte wenden Sie sich an das AMA-Organisationsteam.</p>
                                </ion-card-content>
                            </ion-card>
                        </IonCol>
                    </template>
                    <template v-else >
                        <IonCol v-for="(table,index) in getTables" :key="table.id" class="ion-text-center"  size-xs="12" size-md="6" size-xl="4"
                                @click="table.locked ? undefined : openCheeseList(table.id)">
                            <ion-card color="light" :class="table.locked ? 'locked' : ''">
                                <div v-if="((getActiveRatingsPerTableMethod(table).finished/getActiveRatingsPerTableMethod(table).open)*100) === 100">
                                    <div class="card-pre-header success"></div>
                                </div>
                                <div v-else>
                                    <div class="card-pre-header"></div>
                                </div>
                                <ion-card-header class="ion-margin-top">
                                    <ion-card-title>{{ table.title ?? 'T'+(index+1) }}</ion-card-title>
                                </ion-card-header>
                                <ion-card-content>
                                    <Progress :key="key" :id="table.id" :percentage="((getActiveRatingsPerTableMethod(table).finished/getActiveRatingsPerTableMethod(table).open)*100)"></Progress>
                                    <p>{{getActiveRatingsPerTableMethod(table).finished}} / {{getActiveRatingsPerTableMethod(table).open}}</p>
                                </ion-card-content>
                            </ion-card>
                        </IonCol>
                    </template>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow, onIonViewDidEnter,
} from '@ionic/vue';
import {currentContest} from '../util/ContestService'
import Header from "../components/Header";
import Progress from '../components/Progress'
import {fetchResultPoints, getActiveRatingsPerTable, resultPointsList} from "../util/ResultService";
import { defineComponent } from 'vue';
import {loggedInJuror} from "@/util/JuryService";

export default defineComponent({
    name: "Tables",
    setup() {
        onIonViewDidEnter(() => {
            fetchResultPoints()
        });
    },
    data(){
        return{
            key: 0,
            lockedTables: []
        }
    },
    components: {
        Progress,
        Header, IonPage, IonGrid, IonRow, IonCol, IonContent, IonCard, IonCardTitle, IonCardHeader, IonCardContent
    },
    methods: {
        openCheeseList(id) {
            this.$router.push('/rating/cheeselist/' + id)
        },
        getActiveRatingsPerTableMethod(table){
            return getActiveRatingsPerTable(table)
        },
        setLockedTables(){
           this.lockedTables = JSON.parse(localStorage.getItem('CapacitorStorage.lockedTables')) ?  JSON.parse(localStorage.getItem('CapacitorStorage.lockedTables')) : []
        }
    },
    computed: {
        fetchResultPoints(){
            return resultPointsList.value.length
        },
        getJurorName(){
            if ( loggedInJuror.value) {
                return 'Juror: ' + loggedInJuror.value._firstName +' '+ loggedInJuror.value._lastName
            }
            return ''
        },
        getTables() {
            if (currentContest.value && loggedInJuror.value) {
                const tables = currentContest.value.getTables().filter(table => table.jurorIds.find(id => id.id===loggedInJuror.value.id));
                // eslint-disable-next-line vue/no-async-in-computed-properties

                    const jurorTables = this.lockedTables.filter((val) => {
                        return val.id === loggedInJuror.value.id;
                    })[0];


                    if(jurorTables){
                        return tables.filter((table) => {
                            if(jurorTables.tables.includes(table.id)){
                                table.locked = true
                            }
                            return true
                        })
                    }else{
                        return tables
                    }


            }
            return false
        },
    },
    watch:{
        fetchResultPoints: function (points) {
            this.setLockedTables()
            if(points > 0){
                this.$nextTick(() => {
                    this.key++;
                })
            }

        },
    },

})
</script>

<style>


.table {
    background-color: var(--ion-color-secondary);
    padding: 20px;
}

</style>
