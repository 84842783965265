<template>
    <div :ref="'progress-'+ id" class="progress"></div>
</template>

<script>

export default {
    name: "Progress.vue",
    props: {
        id: {
            type: String,
            required: true
        },
        percentage: {
            type: Number,
            required: true,
            default: null
        }
    },
    data() {
        return {
            options: {
                color: '#d9d1c5',
                trailColor: '#e5e5e5',
                strokeWidth: 30,
                duration: 1000,
                easing: 'easeInOut',
            },
            optionsSuccessfull: {
                color: '#7abf7a',
                trailColor: '#e5e5e5',
                strokeWidth: 30,
                duration: 1000,
                easing: 'easeInOut',
            },
            elem: null
        }
    },
    computed: {
        getPercentage(){
            return this.percentage
        }
    },
    mounted() {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const ProgressBar = require('progressbar.js')
        this.elem = new ProgressBar.SemiCircle(this.$refs['progress-' + this.id], this.getPercentage === 100 ? this.optionsSuccessfull : this.options);
        this.elem.animate(this.getPercentage / 100);
    }
}
</script>
<style>
.progress {
    height: 50px;
}

.progress > svg {
    height: 100%;
    display: block;
}
</style>
